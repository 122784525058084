import React from "react";
import logo from "./logo.svg";
import "./App.css";

function App() {
  return (
    <div className="App">
      <h1>モニュケ</h1>
      <p>モノによるコミュニケーション</p>
      <p>
        <img src="top.jpg" width="600px" alt="Top" />
      </p>
      <a href="https://monyuke.booth.pm/" target="_blank" rel="noreferrer">
        モニュケのショップ
      </a>
      {" | "}
      <a href="https://x.com/YusukeSanekata" target="_blank" rel="noreferrer">
        代表 X アカウント
      </a>
      <br />
      <br />
      <div>
        <p>くまどうじ家</p>
        <p>
          <img src="kumadoujike.png" width="600px" alt="くまどうじ家" />
        </p>
        <p>
          <a
            href="https://www.youtube.com/@KumadoujiKe"
            target="_blank"
            rel="noreferrer"
          >
            Youtube
          </a>
          {" | "}
          <a
            href="https://twitter.com/KumadoujiKe"
            target="_blank"
            rel="noreferrer"
          >
            くまどうじ家 X アカウント
          </a>
          <p>
            くまの熊童子かすみとねこの熊童子でんがくの夫婦がゆるくてとんちきな会話をお届けします。
          </p>
          <p>
            おもしろキットとか、食べたり遊んだりとか。楽しい時間を一緒にすごしましょう！よろしくね！
          </p>
          <div>
            <p>熊童子かすみ</p>
            <p>
              でんがくの家に勝手に住み着いていろいろ楽しいことをしている。
              <br />
              くま年齢5歳。 でんがくと夫婦。 誕生日は5月5日
            </p>
            <p>
              <a
                href="https://x.com/KumadoujiKasumi"
                target="_blank"
                rel="noreferrer"
              >
                熊童子かすみ X アカウント
              </a>
            </p>
          </div>
          <div>
            <p>熊童子でんがく</p>
            <p>
              しゃちょうの家に勝手に住み着いていろいろ楽しいことをしている。
              <br />
              ねこ年齢5歳。
              <br />
              モニュケも作ったり運営したりしてるし、本もでんがくが書いてる。しゃちょうが人間としてかわりにだしてる。
              <br />
              かすみと夫婦。 誕生日は6月6日
              <br />
            </p>
          </div>
        </p>
      </div>
      ～～以下工事中～～
    </div>
  );
  // return (
  //   <div className="App">
  //     <header className="App-header">
  //       <img src={logo} className="App-logo" alt="logo" />
  //       <p>
  //         Edit <code>src/App.tsx</code> and save to reload.
  //       </p>
  //       <a
  //         className="App-link"
  //         href="https://reactjs.org"
  //         target="_blank"
  //         rel="noopener noreferrer"
  //       >
  //         Learn React
  //       </a>
  //     </header>
  //   </div>
  // );
}

export default App;
